import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'

import './sliderHp.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import BannerMp4 from '../../video/bannerVideo.mp4'
import BannerOgv from '../../video/bannerVideo.ogv'
import BannerWebm from '../../video/bannerVideo.webm'


const settings = {
    dots: false,
    infinite: true,
    arrows:false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade:true,
    autoplay:true
};

export default () => (
    <StaticQuery
        query={graphql`
        query {
            allNodeSliderHp(sort: {fields: [created], order: ASC}) {
                edges {
                  node {
                    id
                    title
                    body{
                        value
                    }
                  }
                }
              }
        }
      `}
        render={data => (
            <div className="wd-slider-hp">
                 <video autoplay='true' loop='true' muted='true' className="wd-slider-hp__video"  playsInline>
                        <source src={BannerMp4} type="video/mp4" />
                        <source src={BannerOgv} type="video/ogg" />
                        <source src={BannerWebm} type="video/webm" />
                </video>
                <Slider {...settings} className="wd-slider-hp__slide">
                    {data.allNodeSliderHp.edges.map(({ node }) => (
                        <h1 className="wd-slider-hp__title" key={node.id} dangerouslySetInnerHTML={{ __html: node.body.value }}/>
                    ))}
                </Slider>
            </div>
        )}
    />
)