import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { FaFacebookF } from "react-icons/fa"; 
import { FaInstagram } from "react-icons/fa"; 
import { FaTwitter } from "react-icons/fa"; 

import './contact.scss'


export default () => (
    <StaticQuery
        query={graphql`
        query {
            allBlockContentContactInfos{
                edges{
                node{
                    id
                    info
                    body {
                        value
                        format
                        processed
                        summary
                    }

                    field_facebook
                    field_twitter
                    field_instagram                   
                    }
                }
            }
        }
      `}

      
        render={data => (
            <div className="wd-contact" id="wd-contact">
                <div className="wd-box">
                    <h2 className="wd-box__title wd-box__title--1">contact</h2>
                </div>
            
                <div className="wd-contact__container">
                    <form action="/contact-success" name="contact" className="wd-contact__form" netlify-honeypot="bot-field" data-netlify="true" method="POST">
                    <input name="form-name" type="hidden" value="contact" />
                        <input className="wd-contact__input" id="wd-c-fullname" name="fullname" type="text" placeholder="Nom complet" required />
                        <label for="wd-c-fullname" className="wd-contact__label">Nom complet</label>

                        <input className="wd-contact__input" id="wd-c-email" name="email" type="email" placeholder="Email" required/>
                        <label for="wd-c-email" className="wd-contact__label">Email</label>

                        <input className="wd-contact__input" id="wd-c-telephone" name="telephone" type="text" placeholder="Télephone" required/>
                        <label for="wd-c-telephone" className="wd-contact__label">Télephone</label>

                        <textarea className="wd-contact__textarea" id="wd-c-message" name="message" placeholder="Message" maxlength="350" required></textarea>
                        <label for="wd-c-message" className="wd-contact__label message-label">Message</label>

                        <button className="wd-contact__submit" type="submit">Envoyer</button>
                        <input className="wd-contact__input-hidden" name="bot-field" type="hidden"/>
                    </form>

                    <div className="wd-contact__infos">
                        {data.allBlockContentContactInfos.edges.map(({ node }) => (
                            <div className="wd-contact__content">
                                <h2 className="wd-contact__title">{node.info}</h2>
                                <div className="wd-contact__description" dangerouslySetInnerHTML={{ __html: node.body.value }} ></div>
                                <div className="wd-contact__social-media">
                                    <a className="wd-contact__sm-link" target="_blank" href="https://www.facebook.com/webodrop" rel="noopener noreferrer" aria-label="Webodrop facebook">
                                        <FaFacebookF className="wd-contact__sm-icon"/>
                                    </a>
                                    <a className="wd-contact__sm-link" target="_blank" href="https://twitter.com/webodrop" rel="noopener noreferrer" aria-label="Webodrop twitter">
                                        <FaTwitter className="wd-contact__sm-icon"/>
                                    </a>
                                    <a className="wd-contact__sm-link" target="_blank" href="https://www.instagram.com/webodrop/" rel="noopener noreferrer" aria-label="Webodrop instagram">
                                        <FaInstagram className="wd-contact__sm-icon"/>
                                    </a>
                                </div>
                            </div>               
                        ))}
                    </div>
                </div>
            </div>
        )}
    />
)