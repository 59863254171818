import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { Link } from "@reach/router";

import Logo from '../../img/logo-white.svg'
import GatsbyLogo from '../../img/gatsby.svg'
import DrupalLogo from '../../img/drupal.svg'
import NetlifyLogo from '../../img/netlify.svg'
import './footer.scss';
import { FaFacebookF } from "react-icons/fa"; 
import { FaInstagram } from "react-icons/fa"; 
import { FaTwitter } from "react-icons/fa"; 

import { FaRegSun } from "react-icons/fa";
import { FaRegMoon } from "react-icons/fa";

import addToMailchimp from 'gatsby-plugin-mailchimp'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import ScrollToTop  from "react-scroll-up";
import { Timeline } from 'react-twitter-widgets';


class Footer extends React.Component {
    constructor() {
        super()
        this.state = {
            email: ``,
        }
    }
  _handleEmailChange = e => {
        this.setState({ email: e.target.value })
  }
  _handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    this._postEmailToMailchimp(this.state.email, {
        pathname: document.location.pathname,
    })
  }
  // Post to MC server & handle its response
  _postEmailToMailchimp = (email, attributes) => {
    addToMailchimp(email, attributes)
        .then(result => {
            // Mailchimp always returns a 200 response
            // So we check the result for MC errors & failures
            if (result.result !== `success`) {
                this.setState({
                    status: `error`,
                    msg: result.msg,
                })
            } else {
                // Email address succesfully subcribed to Mailchimp
                this.setState({
                    status: `success`,
                    msg: result.msg,
                })
            }
        })
        .catch(err => {
            // Network failures, timeouts, etc
            this.setState({
                status: `error`,
                msg: err,
            })
        })
}
    render() {
        return (
            <div className="wd-footer">
                <div className="wd-footer__container">
                    <div className="wd-footer__logo-socialmedia">
                        <Link to='/' className="wd-footer__logo-box">
                            <img className="wd-footer__logo" src={Logo} alt="webodrop"/>
                        </Link>
                        <h2 className="wd-footer__title">DEVENEZ NOS AMIS</h2>
                        <p className="wd-footer__subtitle">SUIVEZ NOUS SUR</p>
                        <div className="wd-footer__social-media">
                            <a className="wd-footer__sm-link" target="_blank" href="https://www.facebook.com/webodrop" rel="noopener noreferrer" aria-label="Webodrop facebook">
                                <FaFacebookF className="wd-footer__sm-icon"/>
                            </a>
                            <a className="wd-footer__sm-link" target="_blank" href="https://twitter.com/webodrop" rel="noopener noreferrer" aria-label="Webodrop twitter">
                                <FaTwitter className="wd-footer__sm-icon"/>
                            </a>
                            <a className="wd-footer__sm-link" target="_blank" href="https://www.instagram.com/webodrop" rel="noopener noreferrer" aria-label="Webodrop instagram">
                                <FaInstagram className="wd-footer__sm-icon"/>
                            </a>
                        </div>
                    </div>
                    <div className="wd-footer__menu">
                        <nav className="wd-footer__nav">
                            <ul className="wd-footer__list">
                                {this.props.data.allMenuLinkContentMenuLinkContent.edges.map(({ node }) => (
                                    <li className="wd-footer__item">
                                        <a className="wd-footer__link" href={node.link.uri.replace('internal:', '') + '/'}>{node.title}</a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="wd-footer__twitter-plugin">
                        <Timeline
                            dataSource={{
                            sourceType: 'profile',
                            screenName: 'webodrop'
                            }}
                            options={{
                            username: 'webodrop',
                            height: '350'
                            }}
                        />

                    </div>
                    <div className="wd-footer__newsletter">
                        <h2 className="wd-footer__title">Newsletter</h2>
                        <p className="wd-footer__subtitle">Restez informé de nos dernières news</p>
                        <form className="wd-footer__form" >
                            <input id="footer-email" className="wd-footer__input" type="email" name="email" placeholder="Email Address" onChange={this._handleEmailChange}/>
                            <label for="footer-email" className="wd-footer__label">Email Address</label>

                            <button className="wd-footer__submit" type="submit" onClick={this._handleSubmit}>Envoyer</button>
                        </form>
                        <div className="wd-footer__msg" dangerouslySetInnerHTML={{ __html: this.state.msg }} />
                        <span className="wd-footer__gatsby-drupal">
                        Fièrement propulsé par : 
                            <a className="wd-footer__gatsby-drupal-link" href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer"><img className="wd-footer__gatsby-drupal-img" title="GatsbyJS" src={GatsbyLogo} alt="GatsbyJS"/></a> 
                            + 
                            <a className="wd-footer__gatsby-drupal-link" href="https://www.drupal.org/" target="_blank" rel="noopener noreferrer"><img className="wd-footer__gatsby-drupal-img" title="Drupal" src={DrupalLogo} alt="Drupal"/></a>
                            + 
                            <a className="wd-footer__gatsby-drupal-link netlify" href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer"><img className="wd-footer__gatsby-drupal-img" title="Netlify" src={NetlifyLogo} alt="Netlify"/></a>
                        </span>
                        <div>
                          <ScrollToTop showUnder={160}><button className="wd-footer__scroll-top"><span></span></button></ScrollToTop>
                         </div>
                        <ThemeToggler>
                            {({ theme, toggleTheme }) => (
                                <div className="wd-dl-switcher">
                                    <FaRegSun className="wd-dl-switcher__icon wd-dl-switcher__icon--sun"/>
                                    <label className="wd-dl-switcher__label">
                                        <input
                                            className="wd-dl-switcher__input"
                                            type="checkbox"
                                            onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                                            checked={theme === 'dark'}
                                        />&nbsp;
                                    </label>
                                    <FaRegMoon className="wd-dl-switcher__icon wd-dl-switcher__icon--moon "/>
                                </div>
                            )}
                        </ThemeToggler>
                    </div>
                </div>

                <div className="wd-footer__cr">
                    <span>&copy; {new Date().getFullYear()} Webodrop </span>    
                </div>
            </div>
        )
    }
}
export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allBlockContentServices {
                edges {
                node {
                    info
                    body {
                    value
                    format
                    processed
                    summary
                    }
                }
                }
            }
        
            allBlockContentBasic {
                edges {
                node {
                    info
                    body {
                    value
                    format
                    processed
                    summary
                    }
                }
                }
            }

            allMenuLinkContentMenuLinkContent (sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "footer"}, enabled : {eq : true}}){
                edges{
                  node {
                    id          
                    title
                    link {
                      uri
                    }
                  }
                }
            }
        }
      `}
        render={data => <Footer data={data} {...props}/>}
    />
)