import React from 'react';
import D7CertificationImg from '../../img/d7_certification.png';
import D8CertificationImg from '../../img/d8_certification.png';
import AssociationMembershipImg from '../../img/Drupal_Association.png';
import BadgeSlider from 'react-slick'

import './badges.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    easing: 'linear',
    speed: 2000,
    responsive: [
        {
            breakpoint: 1136,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
  };



const Badges = () => {
    return (
        <div className="wd-badges">
            <div className="wd-box">
                    <h2 className="wd-box__title wd-box__title--1">Achèvements</h2>
            </div>
         <BadgeSlider {...settings} className="wd-badges__slider">
           
                <div className="wd-badges__item">
                    <img src={D7CertificationImg} title="Acquia Drupal 7 certification" alt="Acquia Drupal 7 certification"/>
                </div>
                <div className="wd-badges__item">
                    <img src={AssociationMembershipImg} title="Drupal Association membership" alt="Drupal Association membership"/>
                </div>
                <div className="wd-badges__item">
                   <img src={D8CertificationImg} title="Acquia Drupal 8 certification" alt="Acquia Drupal 8 certification"/>
                </div>
            
            </BadgeSlider>
        </div>
    )
} 

export default Badges