import React from 'react'
import './toggleButton.scss'
// import { FaBars } from "react-icons/fa";

const toggleButton = props => {
    // let buttonClasses =['wd-header__toggle-button'];

    // if(props.show) {
    //     buttonClasses = ['wd-header__toggle-button','wd-header__toggle-button--close']
    // }
    
    return (
        <button className="wd-header__toggle-button" onClick={props.click}>
            <span className="wd-header__burger">&nbsp;</span>
        </button>
    )

};


export default toggleButton;