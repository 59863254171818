import React from 'react';
import { Link } from '@reach/router';
import { IoIosArrowRoundForward } from "react-icons/io"; 
import { StaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import ClientSlider from 'react-slick'

// import googleLogo from '../../img/google_PNG19644.png';
// import amazonLogo from '../../img/amazon_PNG24.png';
// import paypalLogo from '../../img/paypal_PNG22.png';
// import twitchLogo from '../../img/twitch_PNG54.png';
// import disneyLogo from '../../img/walt_disney_PNG47.png';

import './clients.scss'


import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


const settings = {
  arrows:false,
  dots: true,
  infinite: false,
  autoplay: false,
  speed: 700,
  slidesToShow: 10,
  slidesToScroll: 1,
  easing: 'linear',
  speed: 2000,
  responsive: [
      {
        breakpoint: 992,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
        }
      },
      {
        breakpoint: 560,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        }
      }
  ]
};

class Clients extends React.Component{
    render(){
        return(
            <StaticQuery 
                query= { graphql `
                   query {
                    allNodeClients(sort: {order: ASC, fields: field_weight}) {
                        edges {
                          node {
                            id
                            relationships {
                              field_media_img {
                                relationships {
                                  field_media_image {
                                    localFile {
                                      childImageSharp {
                                        fixed(width: 10) {
                                          height
                                          src
                                        }
                                        original {
                                          src
                                          height
                                          width
                                        }
                                        fluid(maxWidth: 250) {
                                          src
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            body {
                              summary
                              format
                              value
                            }
                            title
                          }
                        }
                      }
                  }                  
                  
                `}

                render = { data => (

                    <div className="wd-clients">
                         <div className="wd-box">
                              <h2 className="wd-box__title wd-box__title--1">Nos clients</h2>
                         </div>
                         <div className="wd-clients__container">
                            <ClientSlider {...settings} className="wd-clients__slider">
                                {data.allNodeClients.edges.map(({node}) => (
                                          <div className="wd-clients__item" key={node.id}>
                                              {node.relationships.field_media_img.relationships.field_media_image != null &&
                                                  <Img fluid={node.relationships.field_media_img.relationships.field_media_image.localFile.childImageSharp.fluid} alt={node.title} title={node.title} />
                                              }
                                          </div>
                                    ))}
                              </ClientSlider>
                         </div>
                    </div>
                )}
            />
        )
    }
}

export default Clients;