import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import TeamSlider from 'react-slick' 
import { FaFacebookF } from "react-icons/fa"; 
import { FaInstagram } from "react-icons/fa"; 
import { FaTwitter } from "react-icons/fa"; 

import './team.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    easing: 'linear',
    speed: 2000,
    responsive: [
        {
            breakpoint: 1136,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
 };

const Team = () => (
    <StaticQuery 
        query={graphql`
            query {
                allNodeTeam(sort: {fields: field_weight, order: ASC}, filter: {status : {eq : true}}) {
                edges {
                node {
                    id
                    title
                    field_position
                    field_twitter
                    field_facebook
                    field_instagram

                    relationships {
                    field_media_img {
                        relationships {
                        field_media_image {
                            localFile {
                            childImageSharp {
                                fluid(maxWidth: 267, maxHeight: 350, quality: 90, cropFocus: ENTROPY) {
                                    src
                                }
                            }
                            }
                        }
                        }
                    }
                    }
                }
                }
                }
            }
        `}

            
        render={data => (
            <div className="wd-team">
                <div className="wd-team__borders">
                    <div className="wd-box">
                        <h2 className="wd-box__title wd-box__title--2">Notre équipe</h2>
                    </div>
                    <div className="wd-team__container">
                        <TeamSlider {...settings} className="wd-team__slider">
                            {data.allNodeTeam.edges.map(({node}) => (
                                <div key={node.id} className="wd-team__member">
                                    {node.relationships.field_media_img.relationships.field_media_image != null &&
                                        <Img className="wd-team__img" fluid={node.relationships.field_media_img.relationships.field_media_image.localFile.childImageSharp.fluid} />
                                    }
                                    <div className="wd-team__member-description">
                                        <h2 className="wd-team__full-name">{node.title}</h2>
                                        <span className="wd-team__profession">{node.field_position}</span>
                                    </div>
                                    {(node.field_facebook.trim() != '' || node.field_twitter.trim() != '' || node.field_instagram.trim() != '') &&
                                    <div className="wd-team__social-media">
                                    {node.field_facebook.trim() != '' &&
                                        <a className="wd-team__sm-link" target="_blank" href={node.field_facebook} rel="noopener noreferrer" aria-label="Facebook">
                                            <FaFacebookF className="wd-team__sm-icon"/>
                                        </a>
                                    }
                                    {node.field_twitter.trim() != '' &&
                                        <a className="wd-team__sm-link" target="_blank" href={node.field_twitter} rel="noopener noreferrer" aria-label="Twitter">
                                            <FaTwitter className="wd-team__sm-icon"/>
                                        </a>
                                    }
                                    {node.field_instagram.trim() != '' &&
                                        <a className="wd-team__sm-link" target="_blank" href={node.field_instagram} rel="noopener noreferrer" aria-label="Instagram">
                                            <FaInstagram className="wd-team__sm-icon"/>
                                        </a>
                                    }
                                    </div>
                                    }
                                </div>
                            ))}
                        </TeamSlider>
                    </div>
                </div>
            </div>
        )}
    />
)

export default Team