import React from "react"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { StaticQuery, graphql } from 'gatsby'


import MobileNav from '../components/mobileNav/mobileNav'
import BgOverlay from '../components/bgOverlay/bgOverlay'
import Quotation from '../components/quotation/quotation'
import MessengerCustomerChat from 'react-messenger-customer-chat'


class DefaultLayout extends React.Component {

  state = {
    mobileNavOpen: false,
    quotationOpen : false
  };

  // Methods for Quotation
  quotationToggleClickHandler = () => {
    document.body.classList.add('u-fixed');
    this.setState((prevState) => {
      return { quotationOpen : !prevState.quotationOpen};
    });
  };

  quotaionCloseClickHandler = () => {
    document.body.classList.remove('u-fixed');
    this.setState({
      quotationOpen :false
    });
  };

  // Methods for mobile nav 
  mobileNavtoggleClickHandler = () => {
    document.body.classList.add('u-fixed');
    this.setState((prevState) => {
      return {mobileNavOpen : !prevState.mobileNavOpen};
    });
  };

  mobileNavCloseClickHandler = () => {
    document.body.classList.remove('u-fixed');
    this.setState({
      mobileNavOpen: false
    });
  };

  bgOverlayClickHandler = () => {
    document.body.classList.remove('u-fixed');
    this.setState({
      mobileNavOpen :false,
      quotationOpen :false
    });
  };

  render() {
    let bgOverlay;

    if(this.state.mobileNavOpen) {
      bgOverlay = <BgOverlay click={this.bgOverlayClickHandler}/>;
    }

    if(this.state.quotationOpen) {
      bgOverlay = <BgOverlay click={this.bgOverlayClickHandler}/>;
    }

    return (

      <StaticQuery
        query={graphql`
          query{
            site {
              siteMetadata {
                messengerCustomerChat {
                  pageId
                  appId
                }
              }
            }
          }
      `}
        render ={data => (
          <div>
            <Header
              quotationClick={this.quotationToggleClickHandler} 
              mobileNavClick={this.mobileNavtoggleClickHandler} 
              showMobileNav={this.state.mobileNavOpen}
              showQuotation={this.state.quotationOpen}
            />
            <MobileNav showMobileNav={this.state.mobileNavOpen} click={this.mobileNavCloseClickHandler}/>
            <Quotation showQuotation={this.state.quotationOpen} click={this.quotaionCloseClickHandler}/>
            {bgOverlay}
              {this.props.children}

            <MessengerCustomerChat
                pageId = {data.site.siteMetadata.messengerCustomerChat.pageId}
                appId = {data.site.siteMetadata.messengerCustomerChat.appId}
                language = "fr_FR"
            />
            <Footer/>
          </div>
        )}
      />
      )
  }
}

export default DefaultLayout