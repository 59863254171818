import React from 'react'
import './quotation.scss'
import { MdClear} from "react-icons/md"; 

const quotation = (props) => {

    let quotationClasses = ['wd-quotation'];

    if(props.showQuotation) {
        quotationClasses = ['wd-quotation','wd-quotation--open']
    }

    return(
        <div className={quotationClasses.join(' ')}>
            <MdClear className="wd-quotation__close" onClick={props.click}/>
          <div className="wd-quotation__container">
            <h2 className="wd-quotation__title">Travaillons ensemble !</h2>
            <form action="/quotation-success" name="quotation" className="wd-quotation__form" netlify-honeypot="bot-field" data-netlify="true" method="POST">
            <input name="form-name" type="hidden" value="quotation" />
                <input className="wd-quotation__input-hidden" name="bot-field" type="hidden"/>
                <div className="wd-quotation__form-group wd-quotation__form-group--1">
                    <input className="wd-quotation__input" name="name" type="text" placeholder="Nom complet" id="wd-q-fullname"/>
                    <label for="wd-q-fullname" className="wd-quotation__label">Nom complet</label>

                    <input className="wd-quotation__input" name="email" type="email" placeholder="E-mail" id="wd-q-email"/>
                    <label for="wd-q-email" className="wd-quotation__label">E-mail</label>

                    <input className="wd-quotation__input" name="company" type="text" placeholder="Dénomination sociale / Nom de la Sté. " id="wd-q-company"/>
                    <label for="wd-q-company" className="wd-quotation__label">Dénomination sociale / Nom de la Sté. </label>

                    <textarea className="wd-quotation__textarea"  name="message" placeholder="Message" maxlength="350" id="wd-q-message" required></textarea>
                    <label for="wd-q-message" className="wd-quotation__label message-label">Message</label>
                </div>

                <div className="wd-quotation__form-group wd-quotation__form-group--2">
                    <h2 className="wd-quotation__title wd-quotation__title--2">Services</h2>
                    <div className="wd-quotation__services-box">
                        <select name="service" className="wd-quotation__services" id="wd-q-service">
                            <option value="">Choisir un service</option>
                            <option className="wd-quotation__service" value="consulting">Consulting</option>
                            <option className="wd-quotation__service" value="developpement-web">Développement Web</option>
                            <option className="wd-quotation__service" value="formation">Formation</option>
                            <option className="wd-quotation__service" value="maintenance-drupal">Maintenance Drupal</option>
                            <option className="wd-quotation__service" value="autre">Autre</option>
                        </select>
                        <label for="wd-q-service" className="wd-quotation__label">Choose a service</label>
                    </div>
                </div>

                <button className="wd-quotation__submit" type="submit">Envoyer</button>
            </form>

            </div>
        </div>
    );

};

export default quotation