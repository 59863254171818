import React from 'react'
import Layout from '../layouts/index'
import Slider from '../components/sliderHp/sliderHp'
import Services from '../components/services/services'
import Blog from '../components/blog/blog'
import Testimonials from '../components/testimonials/testimonials'
import Badges from '../components/badges/badges'
import Contact from '../components/contact/contact'
import Clients from '../components/clients/clients'
import Team from '../components/team/team'
import Seo from "../components/seo/seo"



export default () => (
    <Layout>
      <Seo />
      <Slider />
      <Services />
      <Blog />
      <Badges/>
      <Team/>
      <Clients/>
      <Contact/>
    </Layout>
)
