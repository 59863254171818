import React  from "react"
import { StaticQuery, graphql } from 'gatsby'
import ToggleButton from '../mobileNav/toggleButton'
import QuotationTogglebutton from '../quotation/quotationTogglebutton'


import { genericHashLink } from 'react-router-hash-link';
import GatsbyLink from 'gatsby-link';

import './header.scss'
import LogoW from '../../img/logo-white.svg'
import LogoC from '../../img/wd-logo.svg'
import { Link } from "@reach/router";

const MyHashLink = (props) => genericHashLink(props, GatsbyLink);

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.handleScroll = this.handleScroll.bind(this);

    }

    handleScroll() {
        this.setState({scroll: window.scrollY});
    }

    componentDidMount() {
        const el = document.querySelector('header');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        this.state.scroll > this.state.top ? document.body.style.paddingTop = `${this.state.height}px` : document.body.style.paddingTop = 0;
    }

    preventD = e => {
        e.preventDefault();
    }

    render() {

        //header and logo changing state based on scroll
        let headerClasses = ['wd-header'];
        let logoClasses = ['wd-header__logo'];
        let logoSrc = LogoW;


        if (this.state.scroll > this.state.top) {
            //header
            headerClasses = ['wd-header','wd-header--fixed'];

            //logo
            logoClasses = ['wd-header__logo','wd-header__logo--c'];
            logoSrc = LogoC;
        }
        
        return(
            <header className={headerClasses.join(' ')}>
                <Link to='/' className="wd-header__logo-box">
                    <img className={logoClasses.join(' ')} src={logoSrc} alt="webodrop"/>
                </Link>
                <div className="wd-header__nav-box">
                    <div className="wd-header__toggle-button">
                        <ToggleButton click={this.props.mobileNavClick} show={this.props.showMobileNav}/>
                    </div>
                    <nav className="wd-header__nav">
                        <ul className="wd-header__list">
                            {this.props.data.allMenuLinkContentMenuLinkContent.edges.map(({ node }) => (
                                <li key={node.id} className="wd-header__item">
                                    <a className="wd-header__link" href={node.link.uri.replace('internal:', '') + '/'}>{node.title}</a>
                                </li>
                            ))}
                            <li className="wd-header__item">
                                <MyHashLink scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} to="/#wd-contact" className="wd-header__link" >contact</MyHashLink>
                            </li>
                        </ul>
                    </nav> 
                    <QuotationTogglebutton click={this.props.quotationClick} show={this.props.showQuotation}/>
                </div>
            </header>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allMenuLinkContentMenuLinkContent (sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "main"}, enabled : {eq : true}}){
                edges{
                  node {
                    id          
                    title
                    link {
                      uri
                    }
                  }
                }
            }
        }
      `}
        render={data => <Header data={data} {...props}/>}
    />
)
